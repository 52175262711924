import { useContext } from 'react'
import {
  Grid,
  Layout,
  Button,
  Heading,
  Form,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router'
import '../../Budget/Custom.css'
import { useState, useEffect } from 'react'
import { BottomBorderDiv } from '../../Common/CamsDivComponent'
import { VendorDetails } from '../../Common/VendorDetails'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import {
  BudgetStatus,
  Workflow,
} from '../../../globalConfig/common/ConstantData'
import { useAuth } from '@praxis/component-auth'
import * as Yup from 'yup'
import { SET_FIELD, RESET } from '../../../globalConfig/common/ConstantData'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { LocationDetails } from '../../Common/LocationDetails'
import BudgetAmountDetails from '../../Common/BudgetAmountDetails'
import { SubmissionInstructionsBudget } from '../../Common/SubmissionInstructions'
import FiscalYear from '../../Common/FiscalYear'
import ContractDetails from '../../Common/ContractDetails'
import { BudgetExpenses } from '../../Common/BudgetExpenses'
import _ from 'lodash'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import SaveTimelineModal from '../../Common/BudgetView/SaveTimeLineModal'
import { CancelModal } from '../../Common/CancelModal'
import { ErrorModal } from '../../Common/ErrorModal'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import { GenericFunctions } from '../../Utils/GenericFunctions'
import Attachments from '../../Attachment/Attachments'
import { useAttachmentRequest } from '../../hooks/useAttachmentRequest'
import { AttachmentContext } from '../../Context/AttachmentContext'

export const TMCreateBudget = () => {
  const { session } = useAuth()
  const [state, dispatch] = useGlobalForm()
  const { userProfile } = useProfile()
  const env = useEnv()
  const budgetDescr = `${'Created a New Budget '}`
  const budgetTS = new Date().toISOString()
  const [budgetData, setBudgetData] = useState()
  const { getExpenseTypes, getBudgetAmountDetails } = GenericFunctions()

  const [isBudgetShowable, setIsBudgetShowable] = useState(false)

  const { uploadAttachments } = useAttachmentRequest()
  const { uploadedAttachments } = useContext(AttachmentContext)
  const makeToast = useToaster()
  const navigate = useNavigate()

  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.budgetExpenses.length > 0) {
      setIsBudgetShowable(true)
    } else {
      setIsBudgetShowable(false)
    }

    getBudgetAmountDetails(formik)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.budgetExpenses])

  const handleVendorSearch = () => {
    formik.validateForm().then((res) => {
      const fields = ['vendorNumber']
      if (validateFields(res, fields)) {
        formik.setErrors({})
        getVendor()
      }
    })
  }

  const getVendor = async () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'loadingVendorData', value: true }],
    })

    axios
      .get(
        `${env.camsCoreApiUrl}/vendors/${formik.values.vendorNumber}?key=${env.apiKey}`
      )
      .then((res) => {
        if (
          res.data.expense_category_code_name ===
          'Property Management Accounting'
        ) {
          formik.setFieldValue('vendorName', res.data.vendor_name)
        } else {
          formik.setFieldError(
            'vendorNumber',
            'Not Valid Vendor, Please try again'
          )
        }

        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'loadingVendorData', value: false }],
        })
      })
      .catch((error) => {
        console.log('Error when fetching vendor data', error)
        formik.setFieldValue('vendorName', '')
        formik.setFieldError(
          'vendorNumber',
          'Not Valid Vendor, Please try again'
        )
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'loadingVendorData', value: false }],
        })
      })
  }

  const getFiscalYears = () => {
    const d = new Date()
    let years = [d.getFullYear(), d.getFullYear() + 1]
    const yearOptions = years.map((year) => ({
      id: year,
      value: year,
      label: year,
    }))
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'yearOptions', value: yearOptions }],
    })
  }

  const onFiscalYearNextClick = async () => {
    formik.validateForm().then((res) => {
      const fields = ['vendorNumber', 'fiscalYear', 'submittedByEmailId']

      if (validateFields(res, fields)) {
        axios
          .get(
            `${env.camsCoreApiUrl}/location?key=${env.apiKey}&year=${formik.values.fiscalYear}&vendor_no=${formik.values.vendorNumber}`
          )
          .then((res) => {
            const locationOptions = res.data.map((locationIds) => ({
              id: locationIds.location_id,
              value: locationIds.location_id,
              label: locationIds.location_id,
            }))

            dispatch({
              type: SET_FIELD,
              payload: [{ id: 'locationOptions', value: locationOptions }],
            })
            if (locationOptions.length > 0) {
              dispatch({
                type: SET_FIELD,
                payload: [{ id: 'isLocationShowable', value: true }],
              })
              formik.setErrors({})
            } else {
              var errorMsg = `There are no active contracts in the fiscal year: ${formik.values.fiscalYear} for the vendor: ${formik.values.vendorName}`

              dispatch({
                type: SET_FIELD,
                payload: [
                  { id: 'isShowErrorModal', value: true },
                  { id: 'errorMsg', value: errorMsg },
                ],
              })
            }
          })
          .catch((error) => {
            console.log('Error when fetching location data', error)
          })
      }
    })
  }

  const onLocationNextClick = async () => {
    formik.validateForm().then((res) => {
      const fields = ['locationId']
      if (validateFields(res, fields)) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'isContractShowable', value: true }],
        })
        formik.setErrors({})
      }
      handleContractSearch()
    })
  }

  const onContractNextClick = async () => {
    formik.validateForm().then((res) => {
      const fields = ['contractName']
      if (validateFields(res, fields)) {
        axios
          .get(
            `${env.camsCoreApiUrl}/budget_detail?key=${env.apiKey}&year=${formik.values.fiscalYear}&contract_no=${formik.values.contractNo}&doc_type=${formik.values.documentType}`
          )
          .then((res) => {
            makeToast({
              message: `Budget with Vendor:${res.data.vendor_no} and Contract Number:${res.data.contract_no} is already present for the year: ${res.data.year}`,
              heading: 'Duplicate Budget Submission',
              type: 'alert',
              autoHideDuration: 10000,
              links: [
                {
                  text: 'Click here to View Budget',
                  onClick: () => {
                    navigate(`/budget/${res.data.budget_id}`)
                  },
                },
              ],
            })
          })
          .catch((error) => {
            console.log('Error when fetching budget', error)
            dispatch({
              type: SET_FIELD,
              payload: [{ id: 'isCreateBudgetExpenseShowable', value: true }],
            })
          })

        formik.setErrors({})
      }
    })
  }

  const handleLocationSearch = async () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'loadingLocationDetails', value: true }],
    })
    await axios
      .get(
        `${env.camsCoreApiUrl}/location_details?key=${env.apiKey}&year=${formik.values.fiscalYear}&vendor_no=${formik.values.vendorNumber}&location_id=${formik.values.locationId}`
      )
      .then((res) => {
        formik.setFieldValue('locName', res.data[0].loc_name)
        formik.setFieldValue('locAddress', res.data[0].loc_address)
        formik.setFieldValue('locCity', res.data[0].loc_city)
        formik.setFieldValue('locState', res.data[0].loc_state)
        formik.setFieldValue('region', res.data[0].region)
        formik.setFieldValue('contractNo', '')
        getDefaultAnalyst()
        setIsBudgetShowable(false)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isLocAttributesShowable', value: true },
            { id: 'loadingLocationDetails', value: false },
            { id: 'isCreateBudgetExpenseShowable', value: false },
            { id: 'budgetCreatedFlag', value: false },
            { id: 'budgetExpenses', value: [] },
          ],
        })
      })
      .catch((error) => {
        console.log('Error when fetching location data', error)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isLocAttributesShowable', value: false },
            { id: 'loadingLocationDetails', value: false },
          ],
        })
        formik.setFieldError('locationId', 'Please choose valid location')
      })
  }

  const getDefaultAnalyst = async () => {
    await axios
      .get(
        `${env.camsCoreApiUrl}/analyst_data?key=${env.apiKey}&region=${formik.values.region}`
      )
      .then((res) => {
        formik.setFieldValue('assignedUserId', res.data.user_id)
        formik.setFieldValue('assignedUserName', res.data.full_name)
        formik.setFieldValue('auditEntries.assignedUserId', res.data.user_id)
        formik.setFieldValue(
          'auditEntries.assignedUserName',
          res.data.full_name
        )
      })
      .catch((error) => {
        console.log('Error when fetching analyst data', error)
      })
  }

  const getContractNumber = async () => {
    let contractList = state.contractData?.filter(
      (value) => value?.contract_name === formik.values.contractName
    )
    formik.setFieldValue('contractNo', contractList[0].contract_no)
  }
  console.log('uploadedAttachments?.length ', uploadedAttachments?.length)
  const handleContractSearch = async () => {
    await axios
      .get(
        `${env.camsCoreApiUrl}/contract_details?key=${env.apiKey}&year=${formik.values.fiscalYear}&vendor_no=${formik.values.vendorNumber}&location_id=${formik.values.locationId}` //`${env.camsCoreApiUrl}/contract_details?year=${formik.values.fiscalYear}&vendor_no=${formik.values.vendorNumber}&location_id=${formik.values.locationId}`
      )
      .then((res) => {
        const contractOptions = res.data.map((contractNames) => ({
          id: contractNames.contract_name,
          value: contractNames.contract_name,
          label: contractNames.contract_name,
        }))
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'contractOptions', value: contractOptions },
            { id: 'contractData', value: res.data },
          ],
        })
      })
      .catch((error) => {
        console.log('Error when fetching contract data', error)
      })
  }

  function validateFields(errors, fields) {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }

  const handleReset = () => {
    formik.resetForm()
    dispatch({
      type: RESET,
    })
    getFiscalYears()
    getExpenseTypes()
  }

  const schema = Yup.object({
    submittedByEmailId: Yup.string()
      .required('Enter Email Address')
      .email('Enter Valid Email.'),
    fiscalYear: Yup.number()
      .required('Enter Fiscal Year')
      .test('Fine', 'Fiscal Year # should be 4 digits', function (value) {
        var fiscalYearLength = value?.toString().length
        if (fiscalYearLength !== 4) {
          return false
        }
        return true
      }),
    locationId: Yup.string().required('Enter Location'),
    contractName: Yup.string().required('Enter Contract Name'),
  })

  const formik = useFormik({
    initialValues: {
      vendorNumber: '',
      vendorName: '',
      submittedByName: '',
      submittedByEmailId: '',
      status: BudgetStatus.NOT_STARTED,
      workflow: Workflow.PAA,
      fiscalYear: '',
      documentType: 'Budget',
      locationId: '',
      locName: '',
      locAddress: '',
      locCity: '',
      region: 0,
      assignedUserId: '',
      assignedUserName: '',
      contractName: '',
      contractNo: '',
      totalAmount: '',
      totalPrs: '',
      totalAnnualAmount: '',
      totalMonthlyAmount: '',
      auditEntries: {
        activityDescr: budgetDescr,
        userId: userProfile.userId,
        userName: userProfile.fullName,
        workflow: Workflow.PAA,
        status: BudgetStatus.NOT_STARTED,
        activityTs: budgetTS,
        assignedUserId: '',
        assignedUserName: '',
      },
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      const submitheaders = {
        'content-type': 'application/json',
      }
      const budgetRequest = {
        doc_type: values.documentType,
        year: values.fiscalYear,
        contract_no: values.contractNo,
        location_id: values.locationId,
        loc_name: values.locName,
        loc_address: values.locAddress,
        loc_city: values.locCity,
        loc_state: values.locState,
        contract_name: values.contractName,
        submitted_by_name: values.submittedByName,
        submitted_by_email: values.submittedByEmailId,
        total_amount: state.grandTotalExpenseAmount,
        total_prs: state.totalPrsAmount,
        tgt_annual_amount: state.totalAnnualAmount,
        tgt_monthly_amount: state.totalMonthlyAmount,
        vendor_no: values.vendorNumber,
        vendor_name: values.vendorName,
        workflow: values.workflow,
        status: values.status,
        assigned_user_id: values.assignedUserId,
        assigned_user_name: values.assignedUserName,
        is_reopen: false,
        reopen_reason: '',
        create_user_id: session.userInfo.lanId,
        budget_expenses: state.budgetExpenses,
        budget_audit_entries: [
          {
            activity_descr: values.auditEntries.activityDescr,
            user_id: values.auditEntries.userId,
            user_name: values.auditEntries.userName,
            status: values.auditEntries.status,
            workflow: values.auditEntries.workflow,
            assigned_user_id: values.auditEntries.assignedUserId,
            assigned_user_name: values.auditEntries.assignedUserName,
            activity_ts: budgetTS,
          },
        ],
      }

      const submitStatuses = []
      dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'isSubmit', value: true },
          { id: 'creatingBudget', value: true },
          { id: 'budgetCreatedFlag', value: true },
        ],
      })

      axios
        .post(`${env.camsCoreApiUrl}/budget?key=${env.apiKey}`, budgetRequest, {
          headers: submitheaders,
        })
        .then((res) => {
          setBudgetData(res.data)
          submitStatuses?.push({
            message: `Budget Created with Budget Number: ${res?.data?.budget_id}`,
            status: true,
          })
          uploadAttachments(
            res?.data?.budget_id,
            res?.data?.vendor_no,
            'Budget'
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isSubmit', value: false },
              { id: 'budgetCreatedFlag', value: true },
              { id: 'submitStatuses', value: submitStatuses },
            ],
          })
        })
        .catch((error) => {
          console.log('Error when saving budget creation data', error)
          submitStatuses?.push({
            message: 'Unable to create budget, try again',
            status: false,
          })

          console.log('submitStatuses', submitStatuses)
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isSubmit', value: false },
              { id: 'budgetCreatedFlag', value: false },
              { id: 'submitStatuses', value: submitStatuses },
            ],
          })
        })
    },
  })

  return (
    <Layout.Body includeRail>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item xs={12}>
          <Grid.Container justify="space-between" className="hc-pa-none">
            <Grid.Item justify-content="center">
              <Heading
                size={4}
                className="hc-mt-normal hc-mb-normal hc-ml-none"
              >
                Create New Budget
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Button type="primary" onClick={handleReset}>
                RESET BUDGET
              </Button>
            </Grid.Item>
          </Grid.Container>

          <Form onSubmit={formik.handleSubmit}>
            <BottomBorderDiv className="hc-pb-expanded hc-pt-none">
              <VendorDetails
                formik={formik}
                handleVendorSearch={handleVendorSearch}
                isVendor={userProfile.isVendor}
              />
            </BottomBorderDiv>
            <BottomBorderDiv className="hc-pb-expanded hc-pt-none">
              <FiscalYear formik={formik} />
              {!state.isLocationShowable && (
                <Grid.Container direction="row-reverse">
                  <Grid.Item className="hc-pt-none">
                    <Button
                      onClick={() => {
                        onFiscalYearNextClick()
                      }}
                      type="secondary"
                    >
                      Next
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              )}
            </BottomBorderDiv>
            {state.isLocationShowable && (
              <BottomBorderDiv className="hc-pb-expanded hc-pt-none">
                <LocationDetails
                  formik={formik}
                  handleLocationSearch={handleLocationSearch}
                />
                {!state.isContractShowable && (
                  <Grid.Container direction="row-reverse">
                    <Grid.Item className="hc-pt-none">
                      <Button
                        onClick={() => {
                          onLocationNextClick()
                        }}
                        type="secondary"
                      >
                        Next
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
              </BottomBorderDiv>
            )}
            {state.isContractShowable && (
              <BottomBorderDiv className="hc-pb-expanded hc-pt-none">
                <ContractDetails
                  formik={formik}
                  getContractNumber={getContractNumber}
                />
                {!state.isCreateBudgetExpenseShowable && (
                  <Grid.Container direction="row-reverse">
                    <Grid.Item className="hc-pt-none">
                      <Button
                        onClick={() => {
                          onContractNextClick()
                        }}
                        type="secondary"
                        disabled={formik.values.contractNo === ''}
                      >
                        Next
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
              </BottomBorderDiv>
            )}
            {state.isCreateBudgetExpenseShowable && (
              <BudgetExpenses expenseheadingText="Create Budget Expenses" />
            )}
            <br />
            {isBudgetShowable && (
              <BottomBorderDiv className="hc-pb-expanded hc-pt-expanded">
                <BudgetAmountDetails formik={formik} />
              </BottomBorderDiv>
            )}
            <br />
            {state.isCreateBudgetExpenseShowable && <Attachments />}
            {state.isCreateBudgetExpenseShowable &&
              (!isBudgetShowable || uploadedAttachments?.length === 0) && (
                <SubmissionInstructionsBudget />
              )}
            {isBudgetShowable && uploadedAttachments?.length !== 0 && (
              <Grid.Container
                direction="row-reverse"
                className="hc-pt-expanded"
              >
                <Grid.Item hidden={state.isSubmit}>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'isCancel', value: true }],
                      })
                    }}
                    disabled={state.budgetCreatedFlag}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button type="submit" disabled={state.budgetCreatedFlag}>
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            )}
          </Form>
          {state.creatingBudget && (
            <SaveTimelineModal
              redirectUrl={`/budget/${budgetData?.budget_id}`}
              requestId={budgetData?.budget_id}
              vendorNo={budgetData?.vendor_no}
              docType="Budget"
            />
          )}
        </Grid.Item>
        <CancelModal
          headingText="Cancel Budget Creation!"
          heading="Do you want to cancel the Budget Creation?"
        />
        <ErrorModal headingText="Alert!" />
      </Grid.Container>
    </Layout.Body>
  )
}
