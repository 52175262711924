export const SubmissionInstructionsBudget = () => {
  return (
    <div
      style={{
        marginBottom: '20px',
        padding: '15px',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        lineHeight: '1.6',
      }}
      className="hc-mt-expanded"
    >
      <p>
        <strong>Supporting Document:</strong> You need to attach at least one
        supporting document to submit the budget.
      </p>
      <p>
        <strong>Expense:</strong> You need to add at least one expense to
        complete submitting the budget. You can search for bulk expenses to add
        a single line item provided there is a single PRS% and a single admin
        fee%.
      </p>
    </div>
  )
}

export const SubmissionInstructionsActual = () => {
  return (
    <div
      style={{
        marginBottom: '20px',
        padding: '15px',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        lineHeight: '1.6',
      }}
      className="hc-mt-expanded"
    >
      <p>
        <strong>Supporting Document:</strong> You need to attach at least one
        supporting document to submit the reconciliation.
      </p>
      <p>
        <strong>Expense:</strong> You need to add at least one expense to
        complete submitting the reconciliation. You can search for bulk expenses
        to add a single line item provided there is a single PRS% and a single
        admin fee%.
      </p>
    </div>
  )
}
